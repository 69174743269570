import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { PaginationModel, ProjectModel, RequestParamModel } from '../models';
import { BaseService } from '../services';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    public baseService: BaseService
  ) { }
  public API_CONSTANT = "project";

 getProjects(requestParamsModel?: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT, requestParamsModel ? requestParamsModel : new RequestParamModel()).pipe(
      map<any, any>(response => {
        const accountList = new PaginationModel<ProjectModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ProjectModel[];
        return accountList;
 }),
        catchError(err => throwError(() => err))
    );
  }

  saveProjects(body: any) {
    if (body?.id) {
      return this.baseService.patch(`${this.API_CONSTANT}/${body?.id}`, body).pipe(
        map<any, any>(response => {
          return response.body;
        }),
        catchError(err => throwError(() => err))
    );
    } else {
      return this.baseService.uploadPost(this.API_CONSTANT, body).pipe(
        map<any, any>(response => {
          return response.body;
        }),
        catchError(err => throwError(() => err))
    );
    }
  }

  getProjectById(id: number, requestParamModel?: RequestParamModel) {
    return this.baseService.get(`${this.API_CONSTANT}/${id}`, requestParamModel).pipe(
      map<any, any>(response => {
        return response.body.data
        }),
        catchError(err => throwError(() => err))
    );
  }

  deleteProject(id: number) {
    return this.baseService.delete(`${this.API_CONSTANT}/${id}`).pipe(
      map<any, any>(response => {
        return response;
      }),
      catchError(err => throwError(() => err))
    );
  }

  saveProjectImage(body: any) {
    return this.baseService.uploadPost(this.API_CONSTANT + '/upload', body).pipe(map<any, any>(response => {
      return response
    }),
    catchError(err => throwError(() => err))
  );
  }

  saveProjectProducts(data: any) {
    return this.baseService.post(this.API_CONSTANT, data).pipe(
      map<any, any>(response => {
        return response.body
      }),
      catchError(err => throwError(() => err))
  );
  }

  deleteProjectImage(body: any) {
    return this.baseService.delete(this.API_CONSTANT + '/delete-image/' + body.id + '?field=' + body.image).pipe(map<any, any>(data => {
      return data
    }),
    catchError(err => throwError(() => err))
  );
  }

  deleteProjectVideo(faqId: any, field: string) {
    const url = `${this.API_CONSTANT}/delete-video/${faqId}?field=${field}`;

    return this.baseService.delete(url).pipe(
      map<any, any>(response => {
        return response
      }),
      catchError(err => throwError(() => err))
    );
  }


}
