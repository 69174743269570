import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from "./admin-core/components/not-found/not-found.component";
import {AuthGuardService} from "@coreservices";
import { NotificationsComponent } from './admin-core/components/notifications/notifications.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), data: {hideHeader: true}, },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuardService], pathMatch: 'full' },
  { path: 'accounts', loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule) },
  { path: 'contacts', loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule) },
  { path: 'sample-requests', loadChildren: () => import('./sample-requests/sample-requests.module').then(m => m.SampleRequestsModule) },
  { path: 'quotes', loadChildren: () => import('./quotes/quotes.module').then(m => m.QuotesModule) },
  { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule) },
  { path: 'products', loadChildren: () => import('./product/product.module').then(m => m.ProductModule) },
  { path: 'despatch', loadChildren: () => import('./despatch/despatch.module').then(m => m.DespatchModule) },
  { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
  { path: 'blogs', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule) },
  { path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule) },
  { path: 'resource', loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule) },
  { path: 'projects', loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule) },
  { path: 'carrier', loadChildren: () => import('./carriers/carriers.module').then(m => m.CarriersModule) },
  { path: 'consumables-calculator', loadChildren: () => import('./consumables-calculator/consumables-calculator.module').then(m => m.ConsumablesCalculatorModule) },
  { path: 'email-templates', loadChildren: () => import('./email-template/email-template.module').then(m => m.EmailTemplateModule) },
  { path: 'tasks', loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksModule)},
  { path: 'settings', loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule)},
  {path: 'notifications', component: NotificationsComponent},
  // Route for the 404 component
  { path: '404', component: NotFoundComponent, data: {hideHeader: true} },
  // Wildcard route for unmatched routes
  { path: '**', redirectTo: '/404', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
